body {
	font-family: "Roboto", sans-serif;
	margin: 0;
}

* {
	outline: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 0;
	color: #1a1c1e;
}

a {
	text-decoration: none !important;
}

/* .container { max-width: 1500px; padding: 0 15px; margin: 0 auto;} */
.navbar-brand a {
	box-sizing: border-box;
}

button {
	border: 0;
	outline: none !important;
}

section {
	padding-top: 100px;
}

/* p {
  font-size: 16px;
  color: #1a1c1e;
} */
h4 {
	color: #1a1c1e;
}

.btn:focus,
.btn.focus {
	box-shadow: none !important;
}

::selection {
	background-color: #254e64;
	color: #fff;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
	/* scrollbar-width: auto;
  scrollbar-color: #254e64 #DBE9F1; */
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 12px;
}

*::-webkit-scrollbar-track {
	/* background: #DBE9F1; */
}

*::-webkit-scrollbar-thumb {
	/* background-color: #254e64;
  border-radius: 10px;
  border: 2px solid #DBE9F1; */
}

/* signin */
/* .main_center_box{ border-radius: 28px;background: var(--M3-surfaces-light-surface1,linear-gradient(0deg, rgba(0, 99, 155, 0.05) 0%, rgba(0, 99, 155, 0.05) 100%), #FCFCFF);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15); padding: 30px 30px; -webkit-transform: translate(0%, 50%);
  transform: translate(0%, 50%);} */

.main_center_box h6 {
	color: var(--M3-sys-light-on-surface, #1a1c1e);
	text-align: center;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 32px;
	/* 133.333% */
}

.main_center_box .title2 {
	color: var(--M3-sys-light-on-surface-variant, #42474e);
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.1px;
}

.main_center_box p {
	color: #42474e;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	/* 142.857% */
	letter-spacing: 0.1px;
	text-align: center;
	padding: 10px 0 16px;
}

.main_form_box {
	width: 100%;
	border: 1px solid #72777f;
	border-radius: 4px;
	padding: 16px;
	display: flex;
	align-items: center;
	margin-bottom: 4px;
}

.main_form_box .form-group {
	width: 100%;
	margin-left: 11px;
	margin-bottom: 0;
}

.input-field {
	width: 100%;
	border: none;
	background: none;
}

.main_form_box .form-control {
	background: transparent;
	border: unset;
	padding: 0;
	color: var(--M3-sys-light-on-surface-variant, #42474e);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0.5px;
	box-shadow: unset;
}

.main_form_box .input-field::placeholder {
	color: #42474e;
	font-size: 16px;
	font-weight: 400;
}

.bttn_continue {
	display: flex;
	justify-content: flex-end;
}

.bttn_continue button.btn_continue {
	color: #fff;
	font-size: 14px;
	font-style: normal;
	transition: 0.3s all;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.1px;
	border-radius: 100px;
	background: #00639b;
	margin: 15px 0;
	padding: 10px 20px;
	border: 1px solid transparent;
}

.bttn_continue button.btn_continue:hover {
	color: #00639b;
	background: transparent;
	border-color: #00639b;
}

.link_sec a {
	color: var(--M3-sys-light-secondary, #51606f);
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.1px;
	margin: 0 10px;
}

.link_sec a:hover {
	color: #00639b;
	text-decoration: none;
}

/* forgotpassword */
.link_pasword {
	color: #0062a0;
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.1px;
	text-decoration: unset;
}

.input {
	border: unset;
	width: 100%;
	max-width: 300px;
	appearance: none;
	background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E);
	background-repeat: no-repeat;
	background-position: right 0.7rem top 50%;
	background-size: 0.65rem auto;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.1px;
	background-color: transparent;
	margin-left: 0.5rem;
}

.mian_select_too {
	border-radius: 8px;
	border: 1px solid var(--M3-sys-light-outline, #72777f);
	background: #eeedf1;
	display: flex;
	align-items: center;
	padding: 8px 10px;
	margin: 0.8rem auto 1.5rem auto;
	justify-content: center;
	width: fit-content;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.1px;
	word-break: break-word;
}

/* verification */
.verification_sec .form-group {
	border-radius: 4px;
	border: 1px solid var(--M3-sys-light-outline, #75777f);
	margin-bottom: 0;
}

.verification_sec .form-group .otpBox {
	width: 55px;
	height: 55px;
	border: unset;
	border-right: 1px solid #75777f;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	border-radius: 4px;
	text-align: center;
	line-height: 20px;
	letter-spacing: 0.1px;
}

.verification_sec .form-group .otpBox:last-child {
	border-right: unset;
}

.verification_sec .key_word {
	max-width: 50px;
	width: 100%;
}

/* Brea Downtown */
.main_center_box h5 {
	color: var(--M3-sys-light-on-surface, #1b1b1e);
	text-align: center;
	font-size: 32px;
	font-style: normal;
	font-weight: 400;
	line-height: 40px;
	margin-bottom: 1.3rem;
}

.main_center_box h4 {
	color: var(--M3-sys-light-on-surface, #1b1b1e);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	/* 150% */
	letter-spacing: 0.1px;
}

/* .verify-input {} */

.verify-input input:first-child {
	border-left: 1px solid #000 !important;
	color: red;
}

/* mansi css */
.main_center_box {
	border-radius: 28px;
	background-color: #eff4fa;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
		0px 4px 8px 3px rgba(0, 0, 0, 0.15);
	padding: 24px;
}

.link-text-color {
	color: #51606f;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
}

.profile-btn-drpdwn:active {
	border: none !important;
	text-decoration: none !important;
	color: black !important;
}

.profile-btn-drpdwn:hover {
	border: none !important;
	text-decoration: none !important;
	color: black !important;
}

.profile-btn-drpdwn {
	border: none !important;
	text-decoration: none !important;
	color: black !important;
}

.sidebar-nav:active,
.sidebar-nav:hover,
.sidebar-nav:focus {
	background-color: #dbe2f9;
}

.isActiveSidebar {
	background-color: #dbe2f9;
}

.export-csv-btn,
.main-filter-btn {
	border: 1px solid #72777f !important;
}

.csvLink {
	color: #00639b;
}

.export-csv-btn:hover,
.main-filter-btn:hover {
	background-color: none !important;
}

.searchbtn {
	border: none;
	background-color: white;
}

.inputsearchbarmain {
	border: none;
	width: 100%;
}

.searchbarmain {
	border: 1px solid #72777f;
	border-radius: 4px;
	max-width: 240px;
	min-width: auto;
	padding: 3px;
	height: 50px;
	padding-left: 9px;
	display: flex;
}

.pagenotfound {
	height: 576px;
	width: 400px;
	position: absolute;
	/* background: #f4eefa; */
	background: #fdfbff;
	top: 10%;
	left: 40%;
	text-align: center;
	border-radius: 12px;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3),
		0px 8px 12px 6px rgba(0, 0, 0, 0.15);
}

.text-404 {
	color: var(--M3-sys-light-on-surface, #1b1b1e);
	text-align: center;

	/* M3/headline/✏️ large */
	font-family: Roboto;
	font-size: 32px;
	font-style: normal;
	font-weight: 400;
	line-height: 40px;
	/* 125% */
}

.text-pagenotfound {
	color: var(--M3-sys-light-on-surface, #1b1b1e);
	text-align: center;
	/* M3/title/✏️ medium */
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: bold;
	line-height: 24px;
	/* 150% */
	letter-spacing: 0.1px;
}

.text-dess {
	color: var(--M3-sys-light-on-surface, #1b1b1e);
	text-align: center;

	/* M3/body/✏️ medium */
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	/* 142.857% */
	letter-spacing: 0.25px;
}

.TableData {
	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	font-weight: 400;
	line-height: 1.42857;
	text-rendering: optimizeLegibility;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	/* font-size: 2px; */
}

.add-save-btn {
	background: #00639b;
}

.rdt_TableCol {
	color: #000000;
	font-weight: 600;
	font-family: "Roboto", sans-serif;
	font-size: 14px;
	justify-content: space-between;
}

.rdt_TableCell {
	color: #000000;
	font-weight: 400;
	font-family: "Roboto", sans-serif;
	font-size: 14px;
}

.rdt_TableRow,
.rdt_TableHeadRow {
	border-bottom: 1px solid var(--M3-sys-light-outline, #72777f) !important;
}

.singleUserGeneralDataTable {
	padding: 24px 16px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
		0px 4px 8px 3px rgba(0, 0, 0, 0.15);
	margin-bottom: 10px;
	height: 100%;
}

.singleUserAboutDataTable {
	padding: 24px 16px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
		0px 4px 8px 3px rgba(0, 0, 0, 0.15);
}

.single-user-p-main,
.single-tenant-p-main {
	color: var(--M3-sys-light-on-surface-variant, #42474e);
	font-family: "Roboto", sans-serif;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	/* 133.333% */
	letter-spacing: 0.4px;
}

.single-user-p-desc,
.single-tenant-p-desc {
	color: var(--M3-sys-light-on-surface, #1a1c1e);
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	/* 150% */
	letter-spacing: 0.5px;
	margin-top: 7px;
}
.display-wheel-tenants {
	display: flex;
	gap: 4%;
}

.Wheel-Section:nth-child(2) .delete {
	visibility: hidden;
}
.Wheel-Section:nth-child(1) .delete {
	visibility: hidden;
}

.did-floating-label-content {
	position: relative;
	margin-bottom: 20px;
}
.wheel-box .did-floating-label-content {
	margin-bottom: 0 !important;
	display: flex;
	gap: 10px;
	align-items: center;
	width: 33%;
}

.did-floating-label {
	color: #72777f;
	font-size: 13px;
	font-weight: normal;
	position: absolute;
	pointer-events: none;
	left: 15px;
	top: -8px;
	padding: 0 5px;
	background: #fff;
	transition: 0.2s ease all;
	-moz-transition: 0.2s ease all;
	-webkit-transition: 0.2s ease all;
}

.txtarea-description {
	height: 113px !important;
	padding: 20px !important;
}

.did-floating-input,
.did-floating-select {
	font-size: 16px;
	display: block;
	width: 400px;
	height: 56px;
	padding: 0 20px;
	background: #fff;
	color: #323840;
	border-radius: 4px;
	border: 1px solid #42474e;
	box-sizing: border-box;

	&:focus {
		outline: none;

		~ .did-floating-label {
			top: -8px;
			font-size: 13px;
		}
	}
}

.display-wheel-tenants .did-floating-input,
.did-floating-select {
	font-size: 16px;
	display: block;
	width: 100% !important;
	height: 56px;
	padding: 0 20px;
	background: #fff;
	color: #323840;
	border-radius: 4px;
	border: 1px solid #42474e;
	box-sizing: border-box;

	&:focus {
		outline: none;

		~ .did-floating-label {
			top: -8px;
			font-size: 13px;
		}
	}
}

select.did-floating-select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

select.did-floating-select::-ms-expand {
	display: none;
}

.did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
	top: -8px;
	font-size: 13px;
}

.did-floating-select:not([value=""]):valid ~ .did-floating-label {
	top: -8px;
	font-size: 13px;
}

.did-floating-select[value=""]:focus ~ .did-floating-label {
	top: 11px;
	font-size: 13px;
}

.did-floating-select:not([multiple]):not([size]) {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cpath id='Path_1' data-name='Path 1' d='M371,294l4,6,4-6Z' transform='translate(-371 -294)' fill='%23003d71'/%3E%3C/svg%3E%0A");
	background-position: right 15px top 50%;
	background-repeat: no-repeat;
}

.input-group {
	display: flex;

	.did-floating-input {
		border-radius: 0 4px 4px 0;
		border-left: 0;
		padding-left: 0;
	}
}

.input-group-append {
	display: flex;
	align-items: center;
	/*   margin-left:-1px; */
}

.input-group-text {
	display: flex;
	align-items: center;
	font-weight: 400;
	height: 34px;
	color: #323840;
	padding: 0 5px 0 20px;
	font-size: 12px;
	text-align: center;
	white-space: nowrap;
	border: 1px solid #72777f;
	border-radius: 4px 0 0 4px;
	border-right: none;
}

.cursor-pointer {
	cursor: pointer;
}

.tenants-images-add {
	display: flex;
	width: 100%;
	padding: 24px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 32px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
		0px 4px 8px 3px rgba(0, 0, 0, 0.15);
}

.tenant-general-add {
	display: flex;
	width: 100%;
	padding: 24px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 32px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
		0px 4px 8px 3px rgba(0, 0, 0, 0.15);
}

.tenant-contact-add {
	display: flex;
	width: 100%;
	padding: 24px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 32px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
		0px 4px 8px 3px rgba(0, 0, 0, 0.15);
}

.tenant-about-add {
	display: flex;
	width: 100%;
	padding: 24px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 32px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
		0px 4px 8px 3px rgba(0, 0, 0, 0.15);
}

.tenant-wheel-of-fortune-add {
	display: flex;
	width: 100%;
	padding: 24px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 32px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
		0px 4px 8px 3px rgba(0, 0, 0, 0.15);
}

.main-add {
	display: flex;
	/*padding: 24px 16px;*/
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	align-self: stretch;
}

.add-img-tenant {
	display: flex;
	width: 160px;
	height: 32px;
	justify-content: center;
	align-items: center;
	/* margin-bottom: 0px; */
	/* flex-shrink: 0; */
	border-radius: 8px;
	border: 1px solid var(--M3-sys-light-outline, #72777f);
}

.single-tenant-data-tbl,
.single-event-data-tbl {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	align-self: stretch;
}

.tenant-img-row,
.img-event-row {
	display: flex;
	flex-flow: wrap;
	align-items: flex-start;
	gap: 24px;
}

.tenant-img,
.event-img {
	display: flex;
	width: 160px;
	height: 160px;
	justify-content: center;
	align-items: center;
	margin-bottom: 2rem;
}

.row-img {
	display: flex;
	width: 100%;
	height: 100%;
	margin: 0;
}

.disp-secondary-img {
	margin-left: 23px;
}

.disp-third-img {
	margin-left: 25px;
}

.add-tnt-img {
	padding-left: 37px;
}

.img-tenant-row {
	display: flex;
	flex-flow: wrap;
	align-items: flex-start;
	gap: 24px;
}

.tenant-img-ui,
.event-img-ui {
	display: flex;
	width: 100%;
	padding: 24px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 32px;
	border-radius: 10px;
	background: #fff;

	/* M3/Elevation Light/3 */
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
		0px 4px 8px 3px rgba(0, 0, 0, 0.15);
}

/* .tenant-general-ui, */
.tenant-contact-ui,
.tenant-about-ui,
.tenant-general-ui,
.tenant-fortune-ui {
	display: flex;
	width: 100%;
	padding: 24px 16px;
	flex-direction: column;
	align-items: flex-start;
	gap: 32px;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
		0px 4px 8px 3px rgba(0, 0, 0, 0.15);
}

.edit-btn-tenant {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
	border-radius: 100px;
	border: 1px solid var(--M3-sys-light-outline, #72777f) !important;
}

.mail-lable {
	color: var(--M3-sys-light-on-surface-variant, #42474e);
	font-family: "Roboto", sans-serif;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0.4px;
}

.mail-input-setting {
	border: 1px solid var(--BF-black, #000);
	display: flex;
	height: 40px;
	padding: 0px 8px;
	width: 300px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	flex: 1 0 0;
	color: #1a1c1e;
	font-size: 14px;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
}

/* Admin users */

.single-admin-data {
	display: flex;
	width: 100%;
	/* height: 100%; */
	padding: 24px 16px;
	flex-direction: column;
	align-items: flex-start;
	flex-shrink: 0;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3),
		0px 4px 8px 3px rgba(0, 0, 0, 0.15);
	margin: 1rem 0;
}

.single-data-notification {
	height: 380px !important;
}

.singleData {
	height: 360px;
}

/* CSS */
.datetimepickerreact-wrapper {
	position: relative;
}

.datetimepickerreact-wrapper input {
	padding: 10px;
	font-size: 16px;
}

.datetimepickerreact-wrapper::before {
	content: "CSS Placeholder";
	position: absolute;
	top: 50%;
	left: 10px;
	transform: translateY(-50%);
	pointer-events: none;
	color: #999;
	font-style: italic;
}

.datetimepickerreact-wrapper input:focus + ::before {
	display: none;
}

.datetimepickerreact {
	height: 35px;
	width: 255px;
	border: 1px solid #d1d1d1;
	padding: 10px;
}

.rc-time-picker-input {
	position: relative;
	display: inline-block;
	padding: 5px 11px !important;
	height: 36px !important;
	cursor: text;
	font-size: 16px !important;
	line-height: 1.5;
	color: #666;
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	transition: border 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
		background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
		box-shadow 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.LoggedHeader {
	padding: 22px 25px 22px 16px;
	width: 100%;
}

.HeaderSiteName {
	color: var(--BF-black, #000);
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
}

.menuDropdown .btn {
	color: var(--M3-sys-light-on-surface-variant, #42474e);
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.1px;
	border: unset;
}

.menuDropdown .dropdown-menu {
	border-radius: 3px;
	text-align: left !important;
	padding: unset;
	box-shadow: 0 0 10px #00000030;
	border: unset;
}

.menuDropdown .dropdown-menu a {
	color: #42474e;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	display: block;
	padding: 10px 15px;
}

.menuDropdown .dropdown-menu a:hover {
	background-color: #f0f0f0;
}

.loggedSidebar {
	min-width: 320px;
	min-height: 100vh;
	padding: 12px;
	background: linear-gradient(
		0deg,
		rgba(0, 89, 199, 0.05) 0%,
		rgba(0, 89, 199, 0.05) 100%
	);
}

.loggedSidebar .isActiveSidebar {
	background: #dbe2f9;
	color: #141b2c !important;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 0.1px;
}

.loggedSidebar .sidebar-nav {
	color: #44464e;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 0.1px;
	padding: 1rem;
	border-radius: 2rem;
	margin: 0.25rem 0rem;
	gap: 1rem;
	display: flex;
	align-items: center;
	--bs-bg-opacity: 0.1;
}

.MainSection {
	width: calc(100% - 320px);
}

.MainSection .MainTitle {
	background: linear-gradient(
		0deg,
		rgba(0, 99, 155, 0.05) 0%,
		rgba(0, 99, 155, 0.05) 100%
	);
	padding: 16px;
	justify-content: space-between;
	align-items: center;
	display: flex;
	min-height: 74px;
}

.MainSection .MainTitle .TitleText {
	color: #1a1c1e;
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px;
	display: flex;
	/* 127.273% */
}

.MainSection .SiteBtn {
	padding: 9px 25px;
	border-radius: 100px;
	border: 1px solid #72777f;
	color: #00639b;
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.1px;
	/* min-height: 42px; */
	height: 41px;
}

.MainSection .SiteBtn2 {
	padding: 9px 25px;
	border-radius: 100px;
	color: #fff;
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.1px;
	background-color: #00639b;
	/* min-height: 42px; */
	height: 42px;
}

.MainSection .SiteBtn3 {
	padding: 10px 25px;
	color: #1a1c1e;
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	/* 142.857% */
	letter-spacing: 0.1px;
	background: rgba(28, 27, 31, 0.12);
	border: unset !important;
	height: 42px;
}

.MainSection .DeleteBtn {
	color: #ba1a1a;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.1px;
	border: unset !important;
}

.MainSection .conten-div {
	margin: 24px 16px;
}

.MainSection .datetimepickerreact-wrappper,
.MainSection .react-datepicker-wrapper,
.MainSection .datetimepickerreact {
	width: 100% !important;
}

.MainSection input.datetimepickerreact,
.MainSection .UserFilterBox input,
.MainSection .UserFilterBox select,
.MainSection .TenantFilterBox input,
.MainSection .TenantFilterBox select,
.MainSection .EventFilterBox input,
.MainSection .EventFilterBox select,
.MainSection .NotificationFilterBox input,
.MainSection .NotificationFilterBox select,
.MainSection .AdminUserFilterBox input,
.MainSection .AdminUserFilterBox select {
	border: 1px solid #42474e;
	border-radius: 4px;
	padding: 5px;
	padding-left: 9px;
	color: #42474e;
}

.MainSection .EventFilterBox .rc-time-picker {
	display: unset;
}

.MainSection .TabButton {
	padding: 17px 38px;
	cursor: pointer;
	text-align: center;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	background-color: #fcfcff;
	justify-content: flex-end;
	flex: 1.5;
	width: 100%;
	grid-template-columns: auto auto auto auto;
}

.min-btn {
	padding-right: 9%;
}

.min-btn-admin {
	padding-right: 2%;
}

.min-btn-event {
	padding-right: 19%;
}

.min-btn-notification {
	padding-right: 8%;
}

.MainSection .singleUserGeneralDataTable .Title,
.MainSection .singleUserAboutDataTable .Title,
.MainSection .conten-div .BoxTitle {
	color: #000;
	font-size: 28px;
	font-style: normal;
	font-weight: 400;
	line-height: 36px;
	/* 128.571% */
}

.MainSection .conten-div .add-img-tenant .add-img-event label {
	color: #42474e;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.1px;
}

.mian_select_too .emailHiglight {
	display: contents;
}

.InnerHeaderBtn {
	margin-left: 31px;
}

.SidebarMenuMobile #SidebarMenuMobileBtn {
	margin-right: 8px;
	background: linear-gradient(
		0deg,
		rgba(0, 99, 155, 0.14) 0%,
		rgba(0, 99, 155, 0.14) 100%
	);
}

.SidebarMenuMobile,
.SidebarCloseBtn {
	display: none;
}

.SidebarCloseBtn {
	text-align: right;
	width: 100%;
}

.SidebarCloseBtn .close {
	margin: 0rem 0px 1rem 0px;
	font-size: 30px;
	line-height: 18px;
	color: #0059c7;
	padding: 4px 5px;
	opacity: 1;
	background: linear-gradient(
		0deg,
		rgba(0, 89, 199, 0.05) 0%,
		rgba(0, 89, 199, 0.05) 100%
	);
}

/* .TableData .header-text {
  padding: 230px;
}

.TableData .header-text-user {
  padding: 230px;
  padding-left: 0px;
} */

.header-text-container {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	height: 50vh !important;
}

.header-text {
	/* text-align: center; */
}

.header-usertext-container {
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	height: 50vh !important;
}

.header-usertext {
	/* text-align: center;
  padding-right: 170px; */
}

.loading {
	position: fixed;
	left: 0px;
	top: 45%;
	width: 100%;
	height: 100%;
	z-index: 9999;
}

.text {
	text-align: left;
	width: 378px;
	/* height: 12px; */
	/* min-height: 88px; */
	height: 100%;
}

.emailHiglight .selectedEmail {
}

@media (min-width: 2048px) {
}

@media (max-width: 1440px) and (min-width: 1023px) {
	.loggedSidebar {
		min-width: 280px;
	}

	.MainSection {
		width: calc(100% - 280px);
	}

	.TableData nav {
		width: calc(100% - 280px) !important;
	}
}

@media (max-width: 1024px) and (min-width: 767px) {
	.loggedSidebar {
		min-width: 215px;
	}

	.display-wheel-tenants {
		gap: 14%;
	}
	.MainSection {
		width: calc(100% - 215px);
	}

	.TableData nav {
		width: calc(100% - 215px) !important;
	}
}

@media (max-width: 768px) and (min-width: 570px) {
	.loggedSidebar {
		min-width: 195px;
	}
	.display-wheel-tenants {
		gap: 8%;
	}
	.MainSection {
		width: calc(100% - 195px);
	}

	.loggedSidebar .sidebar-nav {
		font-size: 13px;
		padding: 0.8rem;
	}

	.tenant-img-row {
		gap: 10px;
	}

	.img-tenant-row {
		gap: 15px;
	}

	.MainSection .singleUserGeneralDataTable .Title,
	.MainSection .singleUserAboutDataTable .Title,
	.MainSection .conten-div .BoxTitle {
		font-size: 22px;
	}

	.MainSection .SiteBtn2 {
		padding: 1px 25px;
	}

	.MainSection .SiteBtn {
		padding: 1px 25px;
	}

	.TableData nav {
		width: calc(100% - 195px) !important;
	}

	.TableData .header-text {
		padding: 147px;
	}
}

@media (max-width: 600px) and (min-width: 425px) {
	.MainSection {
		/* width: 100%; */
		width: calc(100% - 0px);
	}

	.loggedSidebar {
		flex: 0 0 80%;
		max-width: 80%;
		min-height: 134vh;
		position: absolute;
		left: 0px;
		z-index: 2;
		transition: 0.3s all;
		background: #ffffff;
		height: 100%;
		top: 0px;
		border-radius: 0px;
		box-shadow: 0px 10px 30px #00000029;
		background: #f2f7fd;
	}

	.SidebarHide {
		display: none;
	}

	.SidebarMenuMobile,
	.SidebarCloseBtn {
		display: block;
	}

	.did-floating-input,
	.did-floating-select {
		width: 100%;
	}

	.tenant-img-row {
		gap: 10px;
	}

	.MainSection .singleUserGeneralDataTable .Title,
	.MainSection .singleUserAboutDataTable .Title,
	.MainSection .conten-div .BoxTitle {
		font-size: 22px;
	}

	.MainSection .SiteBtn2 {
		padding: 5px 25px;
		font-size: 14px;
	}

	.MainSection .SiteBtn {
		padding: 10px 12px;
		font-size: 13px;
	}

	.TableData nav {
		width: 100% !important;
	}

	.sidebar-sidemenu {
		display: flex;
		align-items: center;
	}

	.MainSection .MainTitle {
		/* display: block; */
		display: flex;
	}

	.MainSection .MainTitle .TitleText {
		width: 100%;
		display: contents;
	}

	.TableData .header-text {
		padding: 73px;
		margin-top: 65px;
	}
}

@media (max-width: 424px) {
	.MainSection {
		width: calc(100% - 0px);
	}

	.loggedSidebar {
		flex: 0 0 80%;
		max-width: 30%;
		min-height: 134vh;
		position: absolute;
		left: 0px;
		z-index: 2;
		transition: 0.3s all;
		background: #ffffff;
		height: 100%;
		top: 0px;
		border-radius: 0px;
		box-shadow: 0px 10px 30px #00000029;
		background: #f2f7fd;
		width: 100%;
	}

	.SidebarHide {
		display: none;
	}

	.sidebar-sidemenu {
		display: flex;
		align-items: center;
	}

	.SidebarMenuMobile,
	.SidebarCloseBtn {
		display: block;
	}

	.did-floating-input,
	.did-floating-select {
		width: 100%;
	}

	.tenant-img-row {
		gap: 10px;
	}

	.MainSection .singleUserGeneralDataTable .Title,
	.MainSection .singleUserAboutDataTable .Title,
	.MainSection .conten-div .BoxTitle {
		font-size: 22px;
	}

	.MainSection .SiteBtn2 {
		padding: 0px 17px;
		font-size: 12px;
		line-height: normal;
	}

	.MainSection .SiteBtn {
		padding: 9px 14px;
		font-size: 12px;
		/* display: flex; */
		align-items: center;
		line-height: normal;
	}

	.mail-input-setting {
		width: 90%;
	}

	.TableData nav {
		width: 100% !important;
	}

	.MainSection .MainTitle {
		/* display: block; */
		display: flex;
	}

	.MainSection .MainTitle .TitleText {
		width: 100%;
		display: contents;
	}

	.MainTitle .innerheader-text {
		font-size: initial;
		line-height: normal;
	}

	.TableData .header-text {
		padding: 48px;
	}

	.TableData .header-text {
		padding: 48px;
		font-size: 18px;
		margin-top: 55px;
	}

	.rdt_TableCol {
		font-size: 12px;
	}
}

@media (max-width: 375) {
	/* .sidebar-sidemenu .TitleText {
  } */
	.sidebar-sidemenu {
		display: flex;
		align-items: center;
	}

	.MainSection {
		width: 100%;
	}

	.loggedSidebar {
		flex: 0 0 80%;
		max-width: 100%;
		min-height: 134vh;
		position: absolute;
		left: 0px;
		z-index: 2;
		transition: 0.3s all;
		background: #ffffff;
		height: 100%;
		top: 0px;
		border-radius: 0px;
		box-shadow: 0px 10px 30px #00000029;
		background: #f2f7fd;
	}

	.SidebarHide {
		display: none;
	}

	.MainSection .MainTitle .TitleText {
		width: 114px;
	}

	.LoggedHeader {
		width: 110%;
	}

	.SidebarMenuMobile,
	.SidebarCloseBtn {
		display: block;
	}

	.did-floating-input,
	.did-floating-select {
		width: 100%;
	}

	.tenant-img-row {
		gap: 10px;
	}

	.MainSection .singleUserGeneralDataTable .Title,
	.MainSection .singleUserAboutDataTable .Title,
	.MainSection .conten-div .BoxTitle {
		font-size: 22px;
	}

	.MainSection .SiteBtn2 {
		padding-top: 9px;
	}

	.MainSection .SiteBtn {
		padding-top: 6px;
	}

	.MainSection .MainTitle {
		padding-left: 4px;
	}

	.mail-input-setting {
		width: 90%;
	}

	.TableData nav {
		width: 100% !important;
	}
}

@media (max-width: 320px) {
	.MainSection {
		width: 100%;
	}

	.loggedSidebar {
		flex: 0 0 80%;
		max-width: 100%;
		min-height: 134vh;
		position: absolute;
		left: 0px;
		z-index: 2;
		transition: 0.3s all;
		background: #ffffff;
		height: 100%;
		top: 0px;
		border-radius: 0px;
		box-shadow: 0px 10px 30px #00000029;
		background: #f2f7fd;
	}

	.SidebarHide {
		display: none;
		width: 100%;
	}

	.MainSection .MainTitle .TitleText {
		width: 100%;
		display: contents;
	}

	.MainSection .innerheader-text {
		font-size: 15px;
		line-height: normal;
	}

	.LoggedHeader {
		padding: 22px 5px 22px 5px;
		width: 100%;
	}

	.SidebarMenuMobile,
	.SidebarCloseBtn {
		display: block;
	}

	.did-floating-input,
	.did-floating-select {
		width: 100%;
	}

	.tenant-img-row {
		gap: 10px;
	}

	.MainSection .singleUserGeneralDataTable .Title,
	.MainSection .singleUserAboutDataTable .Title,
	.MainSection .conten-div .BoxTitle {
		font-size: 22px;
	}

	.MainSection .SiteBtn2 {
		padding: 1px 25px;
		font-size: 13px;
	}

	.MainSection .SiteBtn {
		padding: 1px 21px;
		font-size: 13px;
	}

	.mail-input-setting {
		width: 90%;
	}

	.TableData nav {
		width: 100% !important;
	}

	.main {
		padding-right: 58%;
	}

	.sidebar-sidemenu {
		display: flex;
		align-items: center;
	}

	.MainSection .TabButton {
		font-size: 10px;
		padding-right: 18px;
		width: 100%;
	}

	.MainSection .DeleteBtn {
		display: list-item;
	}

	.TableData .header-text {
		padding: 48px;
		font-size: 14px;
		margin-top: 55px;
	}

	.rdt_TableCol {
		font-size: 10px;
	}
}

.image-row-datatable {
	height: 100px;
	width: 100px;
	padding: 10px;
	border-radius: 15px;
}

.single-category-img {
	height: 150px;
	width: 150px;
	padding: auto;
	border-radius: 10px;
}

.mian_select_disabled_email {
	border-radius: 8px;
	border: 1px solid var(--M3-sys-light-outline, #72777f);
	background: #eeedf1;
	display: flex;
	align-items: center;
	padding: 6px 8px;
	margin: 0.8rem auto 1.5rem auto;
	justify-content: center;
	/* background: rgba(73, 69, 79, 0.08); */
	width: fit-content;
}

.signin_disabled_email {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	/* Add a specific width or max-width as needed */
}

.rdt_Pagination .sc-iKOmoZ .hVPalX {
	color: #000000 !important;
}

.sc-iKOmoZ {
	color: #000000 !important;
}

.hVPalX {
	color: #000000 !important;
}

.inputsearchbarmain::placeholder {
	color: #42474e;
}

.sc-eAKtBH .gVtWSU {
	color: #000000 !important;
}

.dtbMXc {
	font-size: 20px !important;
	padding: 203px;
}

.sc-iuOOrT {
	margin-top: 15px;
	font-size: 18px !important;
}

.SiteBtn:hover {
	background-color: #00639b !important;
	color: #fff !important;
}

.SiteBtn:hover a {
	color: #fff !important;
}

.csvLink:hover {
	color: #fff;
}

/* .SiteBtn:focus {
  background-color: #00639B;
  color: #fff;
} */

/* .csvLink:focus {
  color: #fff;
} */

.InnerHeaderBtn:hover {
	color: #fff;
}

.SiteBtn2:hover {
	border: 1px solid #72777f !important;
	color: #00639b;
	background: none;
}

.lnOUep {
	text-overflow: clip !important;
	white-space: nowrap;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

.file-upload-label {
	align-items: center;
	justify-content: center;
	padding: 10px 20px;
	color: #000;
	border: 1px solid #72777f;
	border-radius: 5px;
	cursor: pointer;
	margin-bottom: 20px;
}

.file-upload-label span {
	margin-right: 10px;
}

.file-upload-label input[type="file"] {
	display: none;
}

.new-happeninng-file-err {
	margin-top: -18px;
}

/* lodaer  */
.loading {
	position: fixed;
	top: 40%;
	width: 100%;
	height: 100%;
	z-index: 9999;
	align-items: center;
}

.TableData nav {
	width: calc(100% - 320px);
	position: fixed;
	bottom: 0;
	right: 0;
	/* left: 239px; */
	border: 0;
	min-height: auto;
}

.validation-error-message {
	color: red;
}

.modal-btn-close {
	background-color: #6c757d;
	border: 2px solid #dbe9f1;
	border-radius: 10px;
	color: white;
	height: 40px;
	width: 75px;
	font-size: 20px;
	text-align: center;
	padding-top: 4px;
	transition: 0.3s;
}

.modal-btn-close:hover,
.modal-btn-close:focus {
	background-color: #0056b3;
	border: 2px solid #6c757d;
	transition: 0.2s;
}

.modal-btn-ok {
	background-color: #6c757d;
	border: 2px solid #dbe9f1;
	border-radius: 10px;
	color: white;
	height: 40px;
	width: 75px;
	font-size: 20px;
	text-align: center;
	transition: 0.4s;
}

.modal-btn-ok:hover,
.modal-btn-ok:focus {
	background-color: #0056b3;
	border: 2px solid #6c757d;
	transition: 0.2s;
}

.modal-body-text {
	text-align: center;
	height: auto;
	width: auto;
	padding: auto;
}

/* Date Picker */

/* styles.css */

.single-date-picker {
	font-family: Arial, sans-serif;
	width: 300px;
	margin: 0 auto;
}

.selected-dates {
	margin-top: 20px;
}

.selected-dates p {
	margin: 5px 0;
}

.react-date-range {
	display: flex;
	flex-direction: column;
}

.react-date-range__calendar {
	flex: 1;
}

.react-date-range__preview {
	display: flex;
	justify-content: space-between;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-bottom: 10px;
}

.react-date-range__preview-item {
	margin-right: 10px;
}

.react-date-range__day--hovered,
.react-date-range__day--selected {
	background-color: #007bff;
	color: #fff;
}

.react-date-range__day--selected::after {
	content: "";
	display: block;
	width: 8px;
	height: 8px;
	background-color: #fff;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.diabled-input-nlo {
	color: #b9b9b9 !important;
	border: 1px solid #c5c5c5 !important;
}

.diabled-label-nlo {
	color: #d2d3d5 !important;
}

/* Loader */

.sweet-loading {
	padding: 13%;
}

.loader-container {
	padding-left: 0% !important;
}

.loader-tenat {
	padding-left: 46% !important;
}

.loader-container-app {
	padding-left: 45% !important;
}

.single-text {
	line-break: anywhere;
	max-width: 500px;
	width: 100%;
}

.sidebar-btn {
	display: flex;
	width: 100%;
	justify-content: flex-end;
}

/* .no-data-text {
  font-size: 20px;
  font-weight: 400;
} */
